@charset 'utf-8';
/* -------------------------------------------------------
style.css for folkert  w/Sass bootstrap all import
creation date:07-28-2021
scss/style.scss - > css/style.css
font-family: 'M PLUS 1p', sans-serif;a
 ---------------------------------------------------------*/
@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@400;700&display=swap');
@import url('https://cdn.jsdelivr.net/npm/bootstrap-icons@1.8.1/font/bootstrap-icons.css');
@import 'colors.scss';

// Sass変数は最初に代入
//14px
$font-size-root: 87.5%;

$brand-color: #e60012;
$form-required: #faf3dd;
$font-family-sans-serif: 'Noto Sans JP', sans-serif;

$primary: $brand-color;
$card-border-width: 0;
$input-btn-focus-width: 0.1rem;
//$input-focus-box-shadow:;
//btn
$btn-border-width: 2px;
// $btn-border-radius: 0;
$btn-font-weight: 600;
$btn-padding-y: 0.475rem;
// $btn-font-size: 1.085rem;

// table
$fk__table-cell-padding-x: 1rem;
$fk-table-head-bg: #f3f5f6;
$table-striped-order: even;

// carousel
$carousel-indicator-width: 12px;
$carousel-indicator-height: 12px;
$carousel-indicator-spacer: 6px;
$carousel-indicator-active-bg: #292f36;
$carousel-indicator-bg: #fff;
$carousel-control-color: #212529;
$carousel-image-size: 245px;
$carousel-image-radius: 1rem;

//input
$form-select-padding-y: 0.28rem;
$component-active-bg: $bs-default-primary;

//table
$col-note-width: 320px;
$col-select-detail-width: 130px;

//modal
$modal-show-transform: 0.1s;
$modal-fade-transform: 0.1s;

//offcanvas
$offcanvas-horizontal-width: 55vw;
$offcanvas-padding-x: 1.5rem;
$offcanvas-transition-duration: 0.1s;

//accordion
$accordion-button-hover-icon: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='#fff'><path fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/></svg>") !default;

//list
$list-image-size: 80px;
$list-image-radius: 0.75rem;
$pagination-active-bg: $primary;

@import '../../node_modules/bootstrap/scss/bootstrap';

:root {
  --bs-root-font-size: 75%;
  --bs-body-line-height: 1.2;
}

//css 変数はあとから上書き
.table {
  --bs-table-hover-bg: rgba(0, 0, 0, 0.03);
  tbody {
    border-top-width: 0 !important;
  }
}
//override
.table-light {
  --bs-table-bg: #ebebeb;
}
.table-dark {
  //--bs-table-bg:#47495C;
  --bs-table-bg: #515c67;
}

/*======================================
site rest
=======================================*/
html {
  padding: 0;
  margin: 0;
  -webkit-tap-highlight-color: rgba($body-bg, 0.3);
  //font-size:$font-size-root !important;
  font-size: var(--bs-root-font-size) !important;
  &.f12 {
    font-size: 75% !important;
  }
  &.f15 {
    font-size: 93.75% !important;
  }
}

body {
  position: relative;
  -webkit-font-smoothing: subpixel-antialiased;
  -moz-osx-font-smoothing: unset;
  text-rendering: optimizeLegibility;
  -webkit-text-size-adjust: 100%;
  //  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  background-color: #f7f9fc;
}

//selection
// ::-moz-selection {
//   background: rgba($body-color, 0.4);
//   color: rgba($body-bg, 0.9);
// }

// ::selection {
//   background: rgba($body-color, 0.4);
//   color: rgba($body-bg, 0.9);
// }

// .___memo {
// color: rgba(#444, 0.5);
// border: 1px dotted rgba(#444, 0.5);
// padding: 2px 16px;
// background: rgba(#4BBCAB, 0.1);
// font-size: 8rem;
// @include mix.box-sizing(border-box);
// margin: 0;
// width: 100%;
// position: fixed;
// top: 0;
// left: 0;
// z-index: 9999;
// }

.wrapper {
  align-items: stretch;
  display: flex;
  width: 100%;
}

//max-width指定
body[data-layout='boxed'] .wrapper {
  border-left: 1px solid #dee6ed;
  border-right: 1px solid #dee6ed;
  margin: 0 auto;
  max-width: 1800px;
  overflow: hidden;
}

@media (min-width: 1440px) {
  body[data-layout='boxed'] .wrapper {
    width: 90vw;
  }
}

//sidebar
.sidebar {
  z-index: 30;
  background: #fff;
  max-width: 260px;
  min-width: 260px;
  transition: margin-left 0.35s ease-in-out, left 0.35s ease-in-out, margin-right 0.35s ease-in-out,
    right 0.35s ease-in-out;
  box-shadow: 0 0.1rem 0.2rem rgba(0, 0, 0, 0.2);
}

.sidebar-heading {
  line-height: 1;
  font-weight: bold;
  text-transform: uppercase;
  font-size: 18px;
  margin: 0;
}

.sidebar-content {
  transition: margin-left 0.35s ease-in-out, left 0.35s ease-in-out, margin-right 0.35s ease-in-out,
    right 0.35s ease-in-out;
}

//sidebar 固定
.sidebar-simplebar[data-simplebar] {
  height: 100vh;
  left: 0;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  overflow-y: scroll;
  &::-webkit-scrollbar {
    display: none;
  }
}

//sidebar 全体の余白
.sidebar-nav {
  list-style: none;
  padding: 0 0 0 1.5rem !important;
}
//sidebar-アイテムの余白
.sidebar-dropdown {
  & .sidebar-item {
    &:last-child {
      margin-bottom: 0.375rem;
    }
  }
}

.sidebar-link,
a.sidebar-link {
  color: #222;
  cursor: pointer;
  display: block;
  padding: 0.375rem 1.625rem 0.375rem 0;
  position: relative;
  text-decoration: none;
  transition: color 75ms ease-in-out;
}

.sidebar-link i,
.sidebar-link svg,
a.sidebar-link i,
a.sidebar-link svg {
  color: $body-color;
  margin-right: 0.25rem;
}

.sidebar-dropdown .sidebar-dropdown .sidebar-link {
  padding: 0.55rem 1.5rem 0.55rem 4.5rem;
}

.sidebar-dropdown .sidebar-dropdown .sidebar-dropdown .sidebar-link {
  padding: 0.55rem 1.5rem 0.55rem 5.75rem;
}

.sidebar-link:focus {
  outline: 0;
}

.sidebar-link:hover {
  color: rgba($body-color, 0.5);
}

.sidebar-link-item {
  position: relative;
}

.sidebar-link-item:hover i,
.sidebar-link-item:hover svg {
  color: rgba($body-color, 0.5);
}

//お気に入りボタン
.fk__fav-btn {
  opacity: 0;
  transition: opacity 75ms ease-in-out;
  background: none;
  border: none;
}
.sidebar-link-item:hover {
  & .fk__fav-btn {
    opacity: 1;
  }
}
//favボタンアクティブ
.sidebar-link-item .fk__fav-btn[data-fav='fav'] {
  opacity: 1 !important;
  & i {
    color: $primary;
  }
}

//カレント
.sidebar-item .sidebar-link.active:hover,
.sidebar-item > .sidebar-link.active {
  color: #fff;
  background: $primary;
}

.sidebar-item .sidebar-link.active:hover i,
.sidebar-item .sidebar-link.active:hover svg,
.sidebar-item > .sidebar-link.active i,
.sidebar-item > .sidebar-link.active svg {
  color: #e9ecef;
}
.sidebar-dropdown .sidebar-link {
  color: $body-color;
  font-weight: 400;
  padding: 0.55rem 3rem 0.55rem 1.6rem; //padding: .55rem 1.5rem .55rem 3.7rem
}
.sidebar-dropdown .sidebar-item .sidebar-link:hover {
  color: rgba($body-color, 0.5);
  font-weight: 400;
}
.sidebar-dropdown .sidebar-item .sidebar-link.active {
  color: #fff;
  font-weight: 600;
}
.sidebar [data-bs-toggle='collapse'] {
  position: relative;
}

.sidebar [data-bs-toggle='collapse']:before {
  border: solid;
  border-width: 0 0.1rem 0.1rem 0;
  content: ' ';
  display: inline-block;
  padding: 2px;
  position: absolute;
  right: 1.7rem;
  top: 1rem;
  transform: rotate(45deg);
  transition: all 0.2s ease-out;
}

.sidebar [aria-expanded='true']:before,
.sidebar [data-bs-toggle='collapse']:not(.collapsed):before {
  top: 1.2rem;
  transform: rotate(-135deg);
}
.sidebar .sidebar-dropdown [data-toggle='collapse']:before {
  top: 1rem;
}
.sidebar .sidebar-dropdown [aria-expanded='true']:before,
.sidebar .sidebar-dropdown [data-toggle='collapse']:not(.collapsed):before {
  top: 1.2rem;
}

.sidebar-toggle {
  cursor: pointer;
  display: flex;
  height: 26px;
  width: 26px;
}

.sidebar-header {
  background: transparent;
  padding: 1.5rem 0;
}

.sidebar-separator {
  position: relative;
  background: transparent;
  color: #ced4da;
  font-size: 0.75rem;
  padding: 0.375rem 1.5rem 0.375rem;

  &::before {
    width: calc(100% - 0.5rem);
    height: 1px;
    position: absolute;
    left: 0;
    top: 50%;
    transition: translateY(-50%);
    background: $border-color;
    display: block;
    content: '';
  }
}

.control-area {
  display: flex;
  flex-basis: auto;
  position: fixed;
  padding: 0.675rem 1.25rem !important;
  background-color: #fff;
  border-bottom: 1px solid $border-color;
  z-index: 20;
  top: 60px;
  left: 260px;
  width: calc(100% - 260px);
  height: 59px;
  justify-content: space-between;
  transition: all 0.35s ease-in-out;

  &.collapsed {
    left: 0;
    width: 100%;
  }
}

.control-area-space {
  height: 59px;
}

.fk__fav-btn {
  position: absolute;
  right: 10px;
  top: calc(50%);
  transform: translateY(-50%);
}
.badge-sidebar-primary,
.badge-sidebar-secondary {
  position: absolute;
  right: 20px;
  top: 12px;
}
.sidebar-dropdown .badge-sidebar-primary,
.sidebar-dropdown .badge-sidebar-secondary {
  top: 10px;
}
.badge-sidebar-primary {
  background: $brand-color;
  color: #fff;
}
.badge-sidebar-secondary {
  background: #6c757d;
  color: #fff;
}
body .sidebar.collapsed {
  margin-left: -260px;
}

.collapsed {
  &.navbar,
  &.navbar__sub {
    padding: 0.675rem 1.25rem !important;
  }
}

body .sidebar-toggle {
  margin-right: 1rem;
}

.main {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  min-width: 0;
  transition: margin-left 0.35s ease-in-out, left 0.35s ease-in-out, margin-right 0.35s ease-in-out,
    right 0.35s ease-in-out;
  width: 100%;
  position: relative;
  padding: 6.4rem 20px 20px;
  &.fk__has-navbar__sub {
    padding: 11.5rem 32px 32px;
  }
}

@media (max-width: 767.98px) {
  .main {
    overflow-y: hidden;
  }
}

.content {
  direction: ltr;
  flex: 1;
}

@media (max-width: 767.98px) {
  .content {
    max-width: 100vw;
    width: 100vw;
  }
}

/* ==========================================================================
   $ navbar   ========================================================================== */
.navbar-container {
  position: fixed;
  z-index: 21;
  top: 0;
  left: 0;
  width: 100%;
}

.navbar {
  padding: 0.675rem 1.25rem 0.675rem 276px;
  border-bottom: 3px solid $primary;
  //   box-shadow: 0 0 2rem 0 rgba(41, 48, 66, .1);
  transition: padding-left 0.35s ease-in-out, left 0.35s ease-in-out, margin-right 0.35s ease-in-out,
    right 0.35s ease-in-out;
  margin: 0;
  &.navbar-bg {
    background: #fff;
  }
}

.navbar__sub {
  //height: 70px;
  display: flex;
  background: #fff;
  align-items: center;
  padding: 0.675rem 1.25rem 0.675rem 276px;
  border-bottom: 1px solid $border-color;
  transition: padding-left 0.35s ease-in-out, left 0.35s ease-in-out, margin-right 0.35s ease-in-out,
    right 0.35s ease-in-out;
}

@include media-breakpoint-down(lg) {
  .navbar__sub {
    padding: 0.675rem 1.25rem;
  }
}

.navbar__sub-container {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

// @media (min-width:1px) and (max-width:991.98px) {
// .navbar{
//   padding: .875rem 1.25rem
// }
// }

.fk__btn-group {
  display: flex;
  align-items: center;
  gap: 0 20px;

  .fk__btn-group__float-right {
    margin-left: auto;
  }
}

.fk__navbar__sub__delete {
  //margin-left:1rem;
  & a {
    color: $body-color;
  }
}

body .navbar-align {
  margin-left: auto;
}

.nav-icon {
  color: $body-color;
  display: block;
  font-size: 1.5rem;
  line-height: 1.4;
  padding: 0.3rem 0.8rem 0;
  transition: background 0.1s ease-in-out, color 0.1s ease-in-out;
}

.nav-icon:after {
  display: none !important;
}

.nav-flag:hover,
.nav-icon:hover {
  color: $primary;
}

.nav-flag svg,
.nav-icon svg {
  height: 24px;
  width: 24px;
  margin-bottom: 2px;
}

.nav-item .indicator {
  background: $primary;
  border-radius: 50%;
  box-shadow: 0 0.1rem 0.2rem rgba(0, 0, 0, 0.05);
  color: #fff;
  display: block;
  font-size: 0.75rem;
  height: 18px;
  padding: 2px;
  position: absolute;
  right: -10px;
  text-align: center;
  top: -4px;
  transition: top 0.1s ease-out;
  width: 18px;
}

.nav-item:hover .indicator {
  top: -6px;
}

.nav-item a:focus {
  outline: 0;
}

.navbar-nav {
  display: flex;
  flex-direction: column;
  list-style: none;
  margin-bottom: 0;
  padding-left: 0;
}

.navbar-nav .nav-link {
  padding-left: 0;
  padding-right: 0;
}

.navbar-nav .dropdown-menu {
  position: static;
}

.navbar-text {
  padding-bottom: 0.5rem;
  padding-top: 0.5rem;
}

.navbar-collapse {
  align-items: center;
  flex-basis: 100%;
  flex-grow: 1;
  gap: 0 1rem;
}

.navbar-toggler {
  background-color: transparent;
  border: 1px solid transparent;
  border-radius: 0.2rem;
  font-size: 0.95rem;
  line-height: 1;
  padding: 0.25rem 0.75rem;
  transition: box-shadow 0.15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .navbar-toggler {
    transition: none;
  }
}

.navbar-toggler:hover {
  text-decoration: none;
}

.navbar-toggler:focus {
  box-shadow: 0 0 0 0.2rem;
  outline: 0;
  text-decoration: none;
}

.navbar-toggler-icon {
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: 100%;
  display: inline-block;
  height: 1.5em;
  vertical-align: middle;
  width: 1.5em;
}

.navbar-nav-scroll {
  max-height: var(--bs-scroll-height, 75vh);
  overflow-y: auto;
}

.navbar-expand {
  flex-wrap: nowrap;
  justify-content: flex-start;
}

.navbar-expand .navbar-nav {
  flex-direction: row;
}

.navbar-expand .navbar-nav .dropdown-menu {
  position: absolute;
}

.navbar-expand .navbar-nav .nav-link {
  padding-left: 0.75rem;
  padding-right: 1rem;
}

.navbar-expand .navbar-nav-scroll {
  overflow: visible;
}

.navbar-expand .navbar-collapse {
  display: flex !important;
  flex-basis: auto;
}

.navbar-expand .navbar-toggler {
  display: none;
}

.navbar-light .navbar-brand,
.navbar-light .navbar-brand:focus,
.navbar-light .navbar-brand:hover {
  color: rgba(0, 0, 0, 0.9);
}

.navbar-light .navbar-nav .nav-link {
  color: rgba(0, 0, 0, 0.55);
}

.navbar-light .navbar-nav .nav-link:focus,
.navbar-light .navbar-nav .nav-link:hover {
  color: rgba(0, 0, 0, 0.7);
}

.navbar-light .navbar-nav .nav-link.disabled {
  color: rgba(0, 0, 0, 0.3);
}

.navbar-light .navbar-nav .nav-link.active,
.navbar-light .navbar-nav .show > .nav-link {
  color: rgba(0, 0, 0, 0.9);
}

.navbar-light .navbar-toggler {
  border-color: rgba(0, 0, 0, 0.1);
  color: rgba(0, 0, 0, 0.55);
}

.navbar-light .navbar-toggler-icon {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3E%3Cpath stroke='rgba(0,0,0,0.55)' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E");
}

.navbar-light .navbar-text {
  color: rgba(0, 0, 0, 0.55);
}

.navbar-light .navbar-text a,
.navbar-light .navbar-text a:focus,
.navbar-light .navbar-text a:hover {
  color: rgba(0, 0, 0, 0.9);
}

.navbar-dark .navbar-brand,
.navbar-dark .navbar-brand:focus,
.navbar-dark .navbar-brand:hover {
  color: #fff;
}

.navbar-dark .navbar-nav .nav-link {
  color: rgba(255, 255, 255, 0.55);
}

.navbar-dark .navbar-nav .nav-link:focus,
.navbar-dark .navbar-nav .nav-link:hover {
  color: rgba(255, 255, 255, 0.75);
}

.navbar-dark .navbar-nav .nav-link.disabled {
  color: rgba(255, 255, 255, 0.25);
}

.navbar-dark .navbar-nav .nav-link.active,
.navbar-dark .navbar-nav .show > .nav-link {
  color: #fff;
}

.navbar-dark .navbar-toggler {
  border-color: rgba(255, 255, 255, 0.1);
  color: rgba(255, 255, 255, 0.55);
}

.navbar-dark .navbar-toggler-icon {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3E%3Cpath stroke='rgba(255,255,255,0.55)' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E");
}

.navbar-dark .navbar-text {
  color: rgba(255, 255, 255, 0.55);
}

.navbar-dark .navbar-text a,
.navbar-dark .navbar-text a:focus,
.navbar-dark .navbar-text a:hover {
  color: #fff;
}

.navbar-nav .dropdown-menu {
  box-shadow: 0 0.1rem 0.2rem rgba(0, 0, 0, 0.05);
}

// .dropdown .dropdown-menu.show {
//   -webkit-animation-duration: 0.5s;
//   animation-duration: 0.5s;
//   -webkit-animation-fill-mode: forwards;
//   animation-fill-mode: forwards;
//   -webkit-animation-iteration-count: 1;
//   animation-iteration-count: 1;
//   -webkit-animation-name: dropdownAnimation;
//   animation-name: dropdownAnimation;
//   -webkit-animation-timing-function: ease;
//   animation-timing-function: ease;
// }

@-webkit-keyframes dropdownAnimation {
  0% {
    opacity: 0;
    transform: translateY(-8px);
  }

  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes dropdownAnimation {
  0% {
    opacity: 0;
    transform: translateY(-8px);
  }

  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.dropdown-toggle:after {
  border: solid;
  border-width: 0 2px 2px 0;
  display: inline-block;
  padding: 2px;
  transform: rotate(45deg);
}

.dropdown-item {
  transition: background 0.1s ease-in-out, color 0.1s ease-in-out;
}

.dropdown-menu-lg {
  min-width: 20rem;
}

.dropdown .list-group .list-group-item {
  border-width: 0 0 1px;
  margin-bottom: 0;
  text-decoration: none;
}

.dropdown .list-group .list-group-item:first-child,
.dropdown .list-group .list-group-item:last-child {
  border-radius: 0;
}

.dropdown .list-group .list-group-item:hover {
  background: #f4f7f9;
}

.dropdown-menu {
  top: auto;
}

.dropdown-menu-header {
  border-bottom: 1px solid #dee6ed;
  font-weight: 500;
  padding: 0.75rem;
  text-align: center;
}

.dropdown-menu-footer {
  display: block;
  font-size: 0.75rem;
  padding: 0.5rem;
  text-align: center;
}

@media (min-width: 1200px) {
  .dropdown-mega-list {
    min-width: 250px;
  }
}

footer.footer {
  padding: 1rem 0.75rem;
}

footer.footer ul {
  margin-bottom: 0;
}

@media (max-width: 767.98px) {
  footer.footer {
    width: 100vw;
  }
}

.input-group-navbar .btn,
.input-group-navbar .form-control {
  background: #f3f6fb;
  border: 0;
  box-shadow: none;
  height: calc(2.24375rem + 2px);
  padding: 0.35rem 0.75rem;
}

.input-group-navbar .btn:focus,
.input-group-navbar .form-control:focus {
  background: #f3f6fb;
  box-shadow: none;
  outline: 0;
}

.input-group-navbar .btn {
  color: #6c757d;
}

.input-group-navbar .btn .feather {
  height: 20px;
  width: 20px;
}

/* $ メニューボタン   ========================================================================== */
.hamburger,
.hamburger:after,
.hamburger:before {
  background: #495057;
  border-radius: 1px;
  content: '';
  cursor: pointer;
  display: block;
  height: 3px;
  transition: background 0.1s ease-in-out, color 0.1s ease-in-out;
  width: 24px;
}

.hamburger {
  position: relative;
}

.hamburger:before {
  position: absolute;
  top: -8px;
  width: 20px;
}

.hamburger:after {
  bottom: -8px;
  position: absolute;
  width: 16px;
}

.sidebar-toggle:hover .hamburger,
.sidebar-toggle:hover .hamburger:after,
.sidebar-toggle:hover .hamburger:before {
  background: $primary;
}

.hamburger-right,
.hamburger-right:after,
.hamburger-right:before {
  right: 0;
}

/* ==========================================================================
$  folkart   ========================================================================== */
.fk__check {
  border: 1px solid #ff0000;
  background: rgba($primary, 0.2);
}

.fk__h-logo {
  width: auto;
  height: 40px;
}

.fk__page-title {
  & h1 {
    @extend h3;
    color: $primary;
    margin: 0;
    font-weight: 600;
    // line-height: 1.2;
    //  font-size: calc(1.325rem + 0.3vw);
    font-size: $h3-font-size;
  }
}

.fk__btn {
  min-width: 100px !important;
}

.fk__heading__contents {
  @extend h4;
  font-size: $h4-font-size;
  font-weight: 600;
  color: $primary;
}

.fk__alt-form-control {
  display: block;
  width: 100%;
  padding: $input-padding-y 0.35rem $input-padding-y 0.75rem;
  font-family: $input-font-family;
  @include font-size($input-font-size);
  font-weight: $input-font-weight;
  line-height: $input-line-height;
}

.fk__required {
  background: $form-required !important;
}

.fk__alt-form-control__label {
  font-weight: normal;
}

.fk__table-sm {
  margin-bottom: 2rem;
  width: 100%;
  border-top: $table-border-width solid $table-border-color;

  & thead {
    font-size: 93%;
  }

  > :not(:last-child) > :last-child > * {
    border-bottom-color: $table-border-color;
  }

  & th {
    &.fk__th {
      width: 12.5rem !important;
    }
  }

  // stylelint-disable-next-line selector-max-universal
  > :not(caption) > * > * {
    padding: $table-cell-padding-y-sm $fk__table-cell-padding-x !important;
  }

  & td {
    padding: $table-cell-padding-y-sm 0.35rem $table-cell-padding-y-sm !important;
  }
}

.fk__table__editable {
  > :not(caption) > * > * {
    padding: $table-cell-padding-y 1rem !important;
  }
}

.fk__table-th__fix {
  & th {
    width: 11.5rem !important;
    background-color: #ebebeb;
  }
}

.fk__form-control__small {
  display: inline-block !important;
  width: 3.5rem !important;
  margin-right: 0.5rem;
}

.fk__form__unit {
  font-weight: normal;
  margin-left: -1.5rem;
}

.fk__col__note {
  min-width: $col-note-width;
}

.fk__col__select-detail {
  min-width: $col-select-detail-width;
}

.fk__w-table-striped {
  > tbody > tr:nth-of-type(3) {
    --#{$variable-prefix}table-accent-bg: var(--#{$variable-prefix}table-striped-bg);
    color: var(--#{$variable-prefix}table-striped-color);
  }

  > tbody > tr:nth-of-type(4) {
    --#{$variable-prefix}table-accent-bg: var(--#{$variable-prefix}table-striped-bg);
    color: var(--#{$variable-prefix}table-striped-color);
  }

  > tbody > tr:nth-of-type(4n + 7) {
    --#{$variable-prefix}table-accent-bg: var(--#{$variable-prefix}table-striped-bg);
    color: var(--#{$variable-prefix}table-striped-color);
  }

  > tbody > tr:nth-of-type(4n + 8) {
    --#{$variable-prefix}table-accent-bg: var(--#{$variable-prefix}table-striped-bg);
    color: var(--#{$variable-prefix}table-striped-color);
  }
}

//override
.table-hover {
  > tbody > tr:hover {
    // --#{$variable-prefix}table-accent-bg: var(
    //   --#{$variable-prefix}table-hover-bg
    // );
    // color: var(--#{$variable-prefix}table-hover-color);
    --#{$variable-prefix}table-accent-bg: inherit;
    color: inherit;
  }
}

//カルーゼル
//fk__goods-images-slider
.fk__goods-images-slider {
  padding-bottom: 4.4rem;
}

.fk__carousel-indicators {
  bottom: -2.5rem;

  [data-bs-target] {
    background-color: $carousel-indicator-bg;
    border: 1px solid $gray-900;
    border-radius: 50%;
  }

  .active {
    opacity: $carousel-indicator-active-opacity;
    background-color: $carousel-indicator-active-bg;
  }
}

.fk__carousel-control-wrapper {
  background: $gray-300;
  border-radius: 50%;
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.fk__carousel-item__img {
  width: $carousel-image-size;
  height: $carousel-image-size;
  object-fit: cover;
  border-radius: $carousel-image-radius;
}

.fk__list-item__img {
  width: $list-image-size;
  height: $list-image-size;
  object-fit: cover;
  border-radius: $list-image-radius;
}

//datepicker
.fk__datepicker {
  background: #fff !important;
}

.flatpickr-calendar
  .flatpickr-innerContainer
  .flatpickr-weekdays
  .flatpickr-weekday:nth-child(7n + 1),
.flatpickr-calendar
  .flatpickr-innerContainer
  .flatpickr-days
  .flatpickr-day:not(.flatpickr-disabled):not(.prevMonthDay):not(.nextMonthDay):nth-child(7n + 1) {
  color: red;
}

/* 土曜日：青 */
.flatpickr-calendar .flatpickr-innerContainer .flatpickr-weekdays .flatpickr-weekday:nth-child(7),
.flatpickr-calendar
  .flatpickr-innerContainer
  .flatpickr-days
  .flatpickr-day:not(.flatpickr-disabled):not(.prevMonthDay):not(.nextMonthDay):nth-child(7n) {
  color: blue;
}

.flatpickr-current-month {
  display: flex !important;
  justify-content: center;
  align-items: center;
  gap: 0 8px !important;
  font-size: 1rem !important;
}

.cur-month {
  font-weight: normal !important;
}

.flatpickr-current-month .numInputWrapper {
  width: 3.5rem !important;
}

.numInputWrapper {
  display: relative;
  &::after {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    font-size: 85%;
    right: -1rem;
    content: '年';
  }
}
.fk__offcanvas-body {
  padding-bottom: calc(90px + 1.5rem);
}

.fk__offcanvas-set-btn {
  border-top: 1px solid $border-color;
  height: 60px;
  padding: 15px 1.5rem 0px;
  width: 100%;
  background: #fff;
}

.fk__offcanvas-grid {
  display: grid;
  grid-template-rows: auto 1fr;
  height: 100%;
}

.fk__offcanvas-small {
  max-width: 600px;
}

.fk__offcanvas-xsmall {
  max-width: 400px;
}

.fk__offcanvas-middle {
  max-width: 700px;
}

//アコーディオン
.fk__condition-accordion {
  margin: -1rem;
}

.fk__accordion-button {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: $accordion-button-padding-y $accordion-button-padding-x;
  @include font-size($font-size-base);
  color: $body-color;
  text-align: center !important; // Reset button style
  background-color: $accordion-button-bg;
  border: 0;
  @include border-radius(0);
  overflow-anchor: none;
  @include transition($accordion-transition);

  &:not(.collapsed) {
    color: $body-color;
    border-top: 1px solid $border-color;

    &::after {
      background-image: escape-svg($accordion-button-icon);
      transform: $accordion-icon-transform;
    }
  }

  // Accordion icon
  &::after {
    flex-shrink: 0;
    width: $accordion-icon-width;
    height: $accordion-icon-width;
    margin-left: 0.3rem;
    content: '';
    background-image: escape-svg($accordion-button-icon);
    background-repeat: no-repeat;
    background-size: $accordion-icon-width;
    @include transition($accordion-icon-transition);
  }

  &:hover {
    z-index: 2;
    background-color: $primary;
    color: #fff;

    &::after {
      background-image: escape-svg($accordion-button-hover-icon);
    }
  }

  &:focus {
    z-index: 3;
    outline: 0;
    box-shadow: $accordion-button-focus-box-shadow;
  }
}

//news
.fk__news-head {
  font-weight: bold;
  color: #8f000c;
}

.fk__list-group {
  height: 16rem;
  overflow: scroll;
  border-top: $list-group-border-width solid $list-group-border-color;
}

.fk__list-group-item {
  padding: 0;
  border-bottom: $list-group-border-width solid $list-group-border-color !important;
}

.fk__news-item {
  display: flex;
  align-items: center;
  text-decoration: none;
  color: $body-color;
  padding: $list-group-item-padding-y 0.25rem;
  &:hover {
    background: #f4f7f9;
  }
}

.fk__news-date {
  padding: 0.15rem 1rem 0.15rem 0;
  white-space: nowrap;
}
.fk__news-date__h {
  display: block;
  font-size: 85%;
  color: rgba($body-color, 0.75);
}

.fk__news-status {
  white-space: nowrap;
  color: #fff;
  font-weight: 600;
  background: #6c757d;
  padding: 0.15rem 0.75rem;

  &.fk__unread {
    background: #8f000c;
  }
}

.fk__news-title {
  padding: 0.15rem 0 0.15rem 1rem;
}
.fk__news-title__h {
  font-size: 85%;
}
tr.fk__table-selected {
  background: rgba($bs-default-primary, 0.7) !important;
  color: #fff !important;
}
.fk__table-selected {
  background: rgba($bs-default-primary, 0.9) !important;
  color: #fff !important;
}

.fk__info-updated {
  margin: 0;
  padding: 0;

  & li {
    list-style: none;
  }
}

.fk__few-input-layout {
  flex: 0 0 auto;
  @media (min-width: 1099px) {
    width: 100%;
  }
  @media (min-width: 1100px) and (max-width: 1299px) {
    width: 83.33333%;
  }
  @media (min-width: 1300px) and (max-width: 1499px) {
    width: 75%;
  }
  @media (min-width: 1500px) {
    width: 66.66667%;
  }
}

// .row > div{
//   &:last-child{
//     .fk__table-sm{
//       margin-bottom:0;
//     }
//   }
// }

.wj-content.wj-dropdown {
  width: 100%;
}

.wj-header {
  &.wj-cell {
    background-color: #ebebeb !important;
    color: #000;
    font-weight: bold;
  }
}

.wj-colheaders {
  .wj-cell.wj-header {
    text-align: center !important;
    padding: 0.175rem 2px;
  }
}

.wj-cell {
  line-height: $input-line-height;
  background-color: #fff;
  border-bottom: $table-border-width solid $table-border-color;

  // ここでセルの高さを設定
  // https://demo.grapecity.com/wijmo/demos/Grid/Rows/RowHeight/purejs
  padding: 0.175rem 7px;
}

.wj-cells {
  .wj-cell {
    &.wj-alt {
      background: #e0fefe;
    }
    &.wj-state-selected {
      background-color: #1472fd;
      &[aria-readonly='true'] {
        background-color: #6c757d;
      }
    }
    &.wj-state-multi-selected {
      background-color: #619aff;
    }
    .negative {
      color: red;
    }
  }
}

.list-flex-grid {
  .wj-row:first-child {
    .wj-cell.wj-header:first-child {
      border-left: 1px solid rgba(0, 0, 0, 0.2);
    }
  }
  .wj-row {
    .wj-cell:not(.wj-header):first-child {
      border-left: 1px solid rgba(0, 0, 0, 0.2);
    }
  }
  .wj-cells {
    .wj-cell {
      &.wj-state-selected {
        background-color: #619aff;
        &[aria-readonly='true'] {
          background-color: #619aff;
        }
      }
    }
  }
}

.wj-multirow .wj-cell.wj-group-end {
  border-right: 1px solid rgba(0, 0, 0, 0.2);
}

.wj-multirow .wj-cell.wj-record-end:not(.wj-header) {
  border-bottom: $table-border-width solid $table-border-color;
}

.no-scrollbars.wj-flexgrid [wj-part='root'] {
  overflow-y: hidden !important;
}

.wj-group-start {
  &.wj-state-selected {
    & div {
      color: #fff;
    }
  }
}

.wj-cell.wj-group {
  background: #e1e1e1 !important;
  color: #444;
}

.wj-flexgrid .wj-state-sticky .wj-header {
  opacity: 1;
}

.wj-content {
  border: none;
  border-radius: 0;
  // border-top: 1px solid rgba(0, 0, 0, .2);
  // border-left: 1px solid rgba(0, 0, 0, .2);
}

.wj-cell .v-center {
  position: relative;
  top: 50%;
  transform: translateY(-50%);
  white-space: pre-wrap;
}

.list-flex-grid {
  position: relative;
  z-index: 0;
  // .wj-cell.wj-header {
  //   z-index: 2;
  // }

  // .wj-cell.spill {
  //   overflow: visible;
  //   z-index: 1; /* render over empty cells */
  // }
}

.jyoudai-history {
  font-weight: normal;
  background-color: #fffbf0;
  width: 210px;
  padding: 0.5rem 1rem;
}

.table-summary {
  th,
  td {
    border-left: 1px solid rgba(0, 0, 0, 0.2);
    border-right: 1px solid rgba(0, 0, 0, 0.2);
    padding: 0.175rem 7px !important;
  }

  th.currency {
    min-width: 75px;
  }
}

.col-0 {
  flex: 0 0;
}

.drop-hover:hover .dropdown-menu {
  display: block;
}

.form-check .form-check-input {
  margin-left: 0;
}

.fk__required__mark {
  color: red;
}
.fk__table-th__small {
  & th {
    width: 8rem !important;
    background-color: #ebebeb;
  }
}

.form-check .form-check-input {
  margin-right: 5px;
}

.productTable {
  .wj-cell.wj-header {
    padding: 12px 5px 0 5px;
  }
  .wj-cell:not(.wj-header) {
    padding: 22px 5px 0 5px;
  }
  [wj-part='rh'] .wj-cell.wj-header {
    padding: 20px 5px 0 5px;
  }
}

.nowrap {
  white-space: nowrap;
}

.table-product-img {
  margin-bottom: 2rem;
  width: 100%;
  border-top: $table-border-width solid $table-border-color;

  & thead {
    font-size: 93%;
  }

  > :not(:last-child) > :last-child > * {
    border-bottom-color: $table-border-color;
  }

  th,
  td {
    border-left: 1px solid rgba(0, 0, 0, 0.2);
    border-right: 1px solid rgba(0, 0, 0, 0.2);
  }

  th {
    padding: 0.25rem 1rem !important;
  }

  // stylelint-disable-next-line selector-max-universal
  > :not(caption) > * > * {
    padding: 1px;
  }
}

.btn-react-select-search {
  padding: 0;
  width: 35px;
  border-style: solid;
  border-width: 1px;
  border-color: #ced4da;
  border-radius: 0 4px 4px 0;
  background-color: #e2e6ea;

  &:focus {
    box-shadow: none;
  }
}

.btn-circle {
  width: 36px;
  height: 36px;
  border-radius: 18px;
  font-size: 14px;
  text-align: center;
  line-height: 1.8;
  padding: 0;
}

.btn-black {
  background-color: #000000;
  &:hover {
    background-color: #252525;
  }
}

.card-header {
  background-color: #f7f9fc;
}

a.wj-cell-maker {
  color: $component-active-bg;
}

.notification-body {
  line-height: 1.8rem;
  font-size: 14px;
}

.partial-shipment {
  .wj-cells {
    .wj-cell {
      // border-right-color: #f7f7f7;
      // border-bottom-color: #f7f7f7;
      &.wj-state-selected {
        background-color: #1472fd !important;
        &[aria-readonly='true'] {
          background-color: #6c757d !important;
        }
      }
      &.wj-state-multi-selected {
        background-color: #619aff !important;
      }
      color: #000000;
      &.ps-header-0,
      &.ps-header-1,
      &.ps-header-2 {
        background-color: $md-blue-grey-50;
        &.wj-group {
          background-color: $md-blue-grey-100;
        }
      }
      &.ps-header-3,
      &.ps-header-4 {
        background-color: $md-blue-50;
        &.wj-group {
          background-color: $md-blue-100;
        }
      }
      &.ps-header-5 {
        background-color: $md-indigo-50;
        &.wj-group {
          background-color: $md-indigo-100;
        }
      }
      &.ps-header-6,
      &.ps-header-7 {
        background-color: $md-deep-orange-50;
        &.wj-group {
          background-color: $md-deep-orange-100;
        }
      }

      &.ps-header-8,
      &.ps-header-9,
      &.ps-header-10 {
        background-color: $md-orange-50;
        &.wj-group {
          background-color: $md-orange-100;
        }
      }
      &.ps-data-alt {
        background-color: #ffffff;
        &.wj-group {
          background-color: $md-grey-100;
        }
      }
      &.ps-data {
        background-color: $md-cyan-50;
        &.wj-group {
          background-color: $md-cyan-100;
        }
      }
      &.ps-results {
        background-color: $md-blue-grey-50;
        &.wj-group {
          background-color: $md-blue-grey-100;
        }
      }
    }
  }
}

.grid-item-color_error {
  color: #ff0000;
}

.fk__flex-table {
  .form-control,
  .form-select {
    margin-top: 2px;
    margin-bottom: 2px;
  }
}

.fk__login-card {
  width: 500px;
  margin: 80px auto 0 auto;
  padding: 30px 40px;
}

.fk__login-logo {
  width: auto;
  height: 90px;
  margin: 0 auto;
}

.fk__login-btn {
  width: 150px;
}

.fk__notify-card {
  a {
    color: inherit;
    text-decoration: none;
  }
  .fk__notify-data {
    padding: 1rem 1.5rem;
    &:hover {
      background-color: $md-grey-100;
    }
  }
  .bg-primary {
    background-color: $bs-default-primary !important;
  }
  hr {
    margin: 0;
  }

  .fk__notify-card-area {
    height: calc(100vh - 400px);
    overflow: auto;
  }
}

.fk__link {
  color: $bs-default-primary;
  cursor: pointer;
}

.accordion-button {
  &:hover {
    z-index: 0;
  }
  &:active {
    z-index: 0;
  }
}

#messagesDropdown:after {
  content: none;
}

.wj-custom-alert-tooltip {
  font-weight: bold;
  color: #ffffff;
  background-color: #ff0000;
}

.form-readonly-overlay {
  pointer-events: none;
  touch-action: none;

  .fk__datepicker {
    background: #e9ecef !important;
  }

  select {
    background-color: $form-select-disabled-bg;
    border: $form-select-border-width solid $form-select-border-color;
    @include border-radius($form-select-border-radius, 0);
  }
}

.form-readonly-invisible {
  width: 0;
  height: 0;
  opacity: 0;
}

// 商品検索の設定
#search_product .wj-cell {
  height: 55px;
}

// アンカータグの色
a.anker_color {
  color: #000;
  .negative {
    color: red;
  }
  .positive {
    color: #000;
  }
}

.partial-shipment {
  .wj-cells {
    .wj-cell {
      // border-right-color: #f7f7f7;
      // border-bottom-color: #f7f7f7;
      &.wj-state-selected {
        background-color: #1472fd !important;
        &[aria-readonly='true'] {
          background-color: #6c757d !important;
        }
      }
      &.wj-state-multi-selected {
        background-color: #619aff !important;
      }
      color: #000000;
      &.ps-header-0,
      &.ps-header-1,
      &.ps-header-2,
      &.ps-header-3,
      &.ps-header-8,
      &.ps-header-9,
      &.ps-header-10,
      &.ps-header-11 {
        background-color: $md-blue-grey-100;
        &.wj-group {
          background-color: $md-blue-grey-200;
        }
      }
      &.ps-header-4,
      &.ps-header-5,
      &.ps-header-6,
      &.ps-header-7 {
        background-color: $md-grey-100;
        &.wj-group {
          background-color: $md-grey-200;
        }
      }
      &.ps-header-12,
      &.ps-header-13 {
        background-color: $md-blue-50;
        &.wj-group {
          background-color: $md-blue-100;
        }
      }
      &.ps-header-14 {
        background-color: $md-indigo-50;
        &.wj-group {
          background-color: $md-indigo-100;
        }
      }
      &.ps-header-15,
      &.ps-header-16 {
        background-color: $md-deep-orange-50;
        &.wj-group {
          background-color: $md-deep-orange-100;
        }
      }

      &.ps-header-17,
      &.ps-header-18,
      &.ps-header-19 {
        background-color: $md-orange-50;
        &.wj-group {
          background-color: $md-orange-100;
        }
      }
      &.ps-data-alt {
        background-color: #ffffff;
        &.wj-group {
          background-color: $md-grey-100;
        }
      }
      &.ps-data {
        background-color: $md-cyan-50;
        &.wj-group {
          background-color: $md-cyan-100;
        }
      }
      &.ps-results {
        background-color: $md-blue-grey-50;
        &.wj-group {
          background-color: $md-blue-grey-100;
        }
      }
      &.ps-past-date {
        background-color: $md-grey-200;
        &.wj-group {
          background-color: $md-grey-300;
        }
      }
    }
  }
}

.product-size-box {
  display: block;
  .product-size-times {
    display: none;
  }
}
@media (min-width: 1300px) {
  .product-size-box {
    display: flex;
    .product-size-times {
      display: block;
    }
  }
}
.product-size-box-label {
  margin-left: 2px;
  margin-bottom: 2px;
  width: 18px;
}